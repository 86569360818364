/* inter-cyrillic-wght-normal */
@font-face {
    font-family: 'Inter Variable';
    font-style: normal;
    font-display: swap;
    font-weight: 100 900;
    src: url(./variable/inter-cyrillic-wght-normal.woff2) format('woff2-variations');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* inter-cyrillic-wght-italic */
@font-face {
    font-family: 'Inter Variable';
    font-style: italic;
    font-display: swap;
    font-weight: 100 900;
    src: url(./variable/inter-cyrillic-wght-italic.woff2) format('woff2-variations');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* inter-latin-wght-normal */
@font-face {
    font-family: 'Inter Variable';
    font-style: normal;
    font-display: swap;
    font-weight: 100 900;
    src: url(./variable/inter-latin-wght-normal.woff2) format('woff2-variations');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* inter-latin-wght-italic */
@font-face {
    font-family: 'Inter Variable';
    font-style: italic;
    font-display: swap;
    font-weight: 100 900;
    src: url(./variable/inter-latin-wght-italic.woff2) format('woff2-variations');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* inter-cyrillic-400-normal */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: url(./static/inter-cyrillic-400-normal.woff2) format('woff2'), url(./static/inter-cyrillic-400-normal.woff) format('woff');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* inter-cyrillic-400-italic */
@font-face {
    font-family: 'Inter';
    font-style: italic;
    font-display: swap;
    font-weight: 400;
    src: url(./static/inter-cyrillic-400-italic.woff2) format('woff2'), url(./static/inter-cyrillic-400-italic.woff) format('woff');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* inter-cyrillic-500-normal */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-display: swap;
    font-weight: 500;
    src: url(./static/inter-cyrillic-500-normal.woff2) format('woff2'), url(./static/inter-cyrillic-500-normal.woff) format('woff');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* inter-cyrillic-500-italic */
@font-face {
    font-family: 'Inter';
    font-style: italic;
    font-display: swap;
    font-weight: 500;
    src: url(./static/inter-cyrillic-500-italic.woff2) format('woff2'), url(./static/inter-cyrillic-500-italic.woff) format('woff');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* inter-cyrillic-600-normal */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-display: swap;
    font-weight: 600;
    src: url(./static/inter-cyrillic-600-normal.woff2) format('woff2'), url(./static/inter-cyrillic-600-normal.woff) format('woff');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* inter-cyrillic-600-italic */
@font-face {
    font-family: 'Inter';
    font-style: italic;
    font-display: swap;
    font-weight: 600;
    src: url(./static/inter-cyrillic-600-italic.woff2) format('woff2'), url(./static/inter-cyrillic-600-italic.woff) format('woff');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* inter-cyrillic-700-normal */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: url(./static/inter-cyrillic-700-normal.woff2) format('woff2'), url(./static/inter-cyrillic-700-normal.woff) format('woff');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* inter-cyrillic-700-italic */
@font-face {
    font-family: 'Inter';
    font-style: italic;
    font-display: swap;
    font-weight: 700;
    src: url(./static/inter-cyrillic-700-italic.woff2) format('woff2'), url(./static/inter-cyrillic-700-italic.woff) format('woff');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* inter-latin-400-normal */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: url(./static/inter-latin-400-normal.woff2) format('woff2'), url(./static/inter-latin-400-normal.woff) format('woff');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* inter-latin-400-italic */
@font-face {
    font-family: 'Inter';
    font-style: italic;
    font-display: swap;
    font-weight: 400;
    src: url(./static/inter-latin-400-italic.woff2) format('woff2'), url(./static/inter-latin-400-italic.woff) format('woff');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* inter-latin-500-normal */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-display: swap;
    font-weight: 500;
    src: url(./static/inter-latin-500-normal.woff2) format('woff2'), url(./static/inter-latin-500-normal.woff) format('woff');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* inter-latin-500-italic */
@font-face {
    font-family: 'Inter';
    font-style: italic;
    font-display: swap;
    font-weight: 500;
    src: url(./static/inter-latin-500-italic.woff2) format('woff2'), url(./static/inter-latin-500-italic.woff) format('woff');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* inter-latin-600-normal */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-display: swap;
    font-weight: 600;
    src: url(./static/inter-latin-600-normal.woff2) format('woff2'), url(./static/inter-latin-600-normal.woff) format('woff');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* inter-latin-600-italic */
@font-face {
    font-family: 'Inter';
    font-style: italic;
    font-display: swap;
    font-weight: 600;
    src: url(./static/inter-latin-600-italic.woff2) format('woff2'), url(./static/inter-latin-600-italic.woff) format('woff');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* inter-latin-700-normal */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: url(./static/inter-latin-700-normal.woff2) format('woff2'), url(./static/inter-latin-700-normal.woff) format('woff');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* inter-latin-700-italic */
@font-face {
    font-family: 'Inter';
    font-style: italic;
    font-display: swap;
    font-weight: 700;
    src: url(./static/inter-latin-700-italic.woff2) format('woff2'), url(./static/inter-latin-700-italic.woff) format('woff');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
