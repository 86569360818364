@media (max-width: 1199px) {

	.b-filter-price__input,
	.b-filter-f-ch__input {
		width: 90px;
	}

	.b-aside-item__top b {
		font-size: 12px;
		line-height: 14px;
	}

	.b-aside-item__top b span {
		font-size: 12px;
		line-height: 14px;
	}

	.b-product-content__item span {
		font-size: 12px;
	}
}

@media (max-width: 767px) {
	.b-footer.stuck-bottom {
		bottom: 0;
	}

	.b-catalog-filters__left {
		flex-wrap: wrap;
	}

	.b-content-title h3 {
		margin: 0 0 10px 0;
	}

	.b-aside-item__top b {
		font-size: 11px;
		line-height: 13px;
	}

	.b-aside-item__top b span {
		font-size: 10px;
		line-height: 12px;
	}

	.b-content-title__two {
		padding: 4px;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}

	.b-aside-xs {
		display: none;
	}

	.b-aside-modal.mobile-fullsize .b-aside-modal__content .tum-flt-aside-modal-title .btn {
		text-align: left;
		width: calc(100% - 15px);
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
}

@media (max-width: 575px) {
	.b-footer-menu {
		margin: 0 0 15px 0;
	}

	.b-aside-xs {
		display: none;
	}


	.b-aside-modal .collapse-header:after {
		transform: translate3d(0, -50%, 0);
	}

	.b-aside-modal.mobile-fullsize .b-filter-price__wrapper {
		margin: 10px 0;
	}

	.b-aside-modal.mobile-fullsize .section-title {
		margin: 15px 0 10px;
	}

	.b-aside-modal.mobile-fullsize .card {
		border-radius: 0;
		border: 0;
	}

	.b-aside-modal.mobile-fullsize .collapse-header {
		display: block;
		position: relative;
	}

	.b-bottom__nav ul li {
		display: block;
		width: 100%;
	}
}

@media (max-width: 767px) {

	.b-aside-modal {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
	}

	.b-aside-modal>.b-aside-modal__content {
		position: absolute;
		top: 70px;
		bottom: 0;
		overflow: auto;
		width: 100%;
	}

	.b-aside-modal.mobile-fullsize {
		padding: 0;
	}

	.b-aside-modal.mobile-fullsize>.b-aside-item__top {
		padding: 7px;
		margin: 0;
	}

	.b-aside-modal.mobile-fullsize>.b-aside-item__top .title {
		margin: 0;
	}

	.b-aside-modal.mobile-fullsize .b-aside-modal__content .b-aside-item__top {
		background-color: rgba(0, 0, 0, .03);
		padding: 5px;
		margin: 0;
	}

	.b-aside-modal .collapse-header:after {
		font-family: 'Font Awesome 5 Free';
		font-weight: bold;
		content: "\f078";
		float: right;
		margin-right: 10px;
		position: absolute;
		right: 5px;
		top: 50%;
	}

	.b-aside-modal .collapse-header.in:after {
		transform: rotate(180deg);
		top: 38%;
	}

	.b-aside-modal .collapse-header+.b-aside-modal__content {
		padding: 0 20px;
		margin-top: 10px;
		margin-bottom: 15px;
		display: none;
	}

	.b-aside-modal.mobile-fullsize .b-filter-price__wrapper {
		margin: 10px 0;
	}

	.b-aside-modal.mobile-fullsize .section-title {
		margin: 15px 0 10px;
	}

	.b-aside-modal.mobile-fullsize .card {
		border-radius: 0;
		border: 0;
	}

	.b-aside-modal.mobile-fullsize .collapse-header {
		display: block;
		position: relative;
	}

}

@media (max-width: 1280px) {
	#navMainInfo .nav-item {
		flex: 1;
		text-align: center;
	}
}

.tum-home-mobilefooter {
	display: none;
}

@media (max-width: 575px) {
	.tum-home-mobilefooter {
		display: block;
	}

	.tum-home-mobilehidden {
		display: none !important;
	}

	.tum-home-spaceAboveDemo {
		display: none;
	}

	.tum-home-allcatalog {
		margin-top: 1em;
	}
}

@media (max-width: 992px) {
	.tum-home-spaceAboveDemo {
		display: none;
	}
}

@media(max-width:667px) {
	.b-filter-price__slider .ui-state-default {
		top: -1px !important;
	}
}

@media (hover: none) {

	.tum-isFix-hint,
	.tum-text-info,
	.hideUnderFieldMsg,
	.hideHint {
		padding: 5px;
	}
}

@media (max-width: 320px) {

	#recaptcha,
	#recaptcha1,
	#recaptcha2 {
		transform: scale(0.86);
		-webkit-transform: scale(0.86);
		transform-origin: 0 0;
		-webkit-transform-origin: 0 0;
	}
}