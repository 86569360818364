.tum-agreement-cookies-window {
	-webkit-transform: translateZ(0px);
	-ms-transform: translateZ(0px);
	transform: translateZ(0px);
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0px;
	left: 0px;
	overflow: hidden;
	z-index: 1;
	visibility: hidden;
}
.tum-agreement-wrap {
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	background: rgba(38, 38, 38, 0.8);
	position: absolute;
	bottom: 0px;
	left: 0px;
	right: 0px;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	justify-content: center;
	padding: 24px 0;
	color: #ffffff;
	visibility: visible;
}

.tum-agreement-cookies-window p {
	text-align: justify;
	color: white !important;
	text-align: center !important;
}

.tum-agreement-cookies-window {
	margin: 0 !important;
	border-radius: 0 !important;
	border: 0 !important;
	background: none !important;
	padding: 0 !important;
	z-index: 10002;
}

.tum-agreement-cookie-ok {
	font-size: 90%;
	outline: none;
	color: white;
	background-color: transparent !important;
	border-color: none !important;
	position: absolute;
	right: 8px;
	top: 5px;
}
.tum-agreement-cookie-ok:active{
	outline:none;
}

.agreement-cookies-status-buttons{
	margin-left: 5px;
}