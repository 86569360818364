span[data-error-object=panelNotResult] {
	font-size: 16px;
	line-height: 1.5;
}

.tum-h1 {
	line-height: 30px;
	display: inline-block;
	font-size: 16px;
	font-weight: normal;
}

.tum-header-menuIcon + a {
	max-width: 255px !important;
	overflow: hidden;
	text-overflow: ellipsis;
}

/*---------------form-search-----------------*/
@media (max-width: 767px) {
	.tum-form-search {
		width: 100%;
	}
}

/*---------------clear-btn-----------------*/


.tum-search-block .btn{
	padding: .2rem .65rem;
}

.hr-1{
	border-top: 1px solid #ced4da;
}