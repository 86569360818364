.mob-searchMenu {
    position: fixed;
    background: white;
    z-index: 7;
    width: 100%;
}

.searchMenu .searchMenu-content {
    overflow-y: scroll;
    height: 100vh;
    overscroll-behavior: contain;
}

.searchMenu-header {
    position: sticky;
    top: 0;
    background: white;
    z-index: 1;
}

.searchMenu-search {
    flex-grow: 1;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    position: relative;
    overflow: hidden;
}

.searchMenu-search .focusArea {
    flex-grow: 1;
    display: flex;
    align-items: center;
    gap: 0.5rem;
	min-width: 0;
}

.searchMenu-search input {
    flex-grow: 1;
    border: none;
    background: transparent;
}

.searchMenu-search .clearField {
    border-right: 1px solid #d1d1d1;
    padding-right: 1rem;
    display: none;
}

.mob-searchMenu .searchTypes-list {
    width: 100%;
    background: white;
    position: absolute;
    left: 0;
    top: 0;
    border-top: none;
    box-shadow: 0 0.8rem 1rem rgba(0, 0, 0, 0.25) !important;
    list-style: none;
    padding-left: 0.5rem;
}

.mob-searchMenu .searchTypes-list li {
    padding: 0.5rem;
}

.mob-searchMenu .searchTypes-list .searchTypes-list-item {
    color: black;
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.mob-searchMenu .searchTypes-list .searchTypes-list-item.active {
    color: var(--color-primary);
}

.searchMenu .searchMenuItem-photo {
	height: 25px;
	width: 25px;
    display: block;
}

.searchMenu .searchMenuItem-photo img {
	border-radius: 50%;
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.searchMenu .searchMenuItem {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.searchMenu .searchMenuItem-icon {
	height: 25px;
	width: 25px;
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
}

.searchMenu .searchMenuItem-content {
    padding: 0.5rem 0;
    border-bottom: 1px solid #d1d1d1;
    flex-grow: 1;

    /*
     нужно явно задать ширину, чтобы работало сокращение текстов
     100% - ширина фото/иконки - отступ
    */
    max-width: calc(100% - 25px - 0.5rem); 
}

.searchMenu .searchMenuItem-content.w-100 {
    max-width: initial;
}

.searchMenu .searchMenuItem-content-info {
    max-width: calc(100% - 0.5rem - 10px);
}
.searchMenu .searchMenuItem-content-info2 {
    max-width: calc(100% - 0.5rem - 50px);
}

.searchMenu .searchHistory-item {
    text-transform: capitalize;
}

.mob-searchMenu .searchMenu-search .searchTypeIcon {
    display: none;
}

.searchMenu .searchTypeIcon-text {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 6px;
	right: 3px;
	background: #eee;
	border-radius: 50%;
	height: 25px;
	width: 25px;
}

.mob-searchMenu .searchMenu-search .focusArea:focus-within + .selectSearchType .searchTypeIcon {
    display: block;
}

.mob-searchMenu .searchMenu-search .focusArea:focus-within + .selectSearchType .searchTypeName {
    display: none;
}

.mob-searchMenu .searchMenu-search .focusArea:focus-within .clearField {
    display: block;
}

.tum-catalog-children.l2 ul {
    margin-left: 2.2rem;
}

.tum-catalog-children.l2 .searchMenuItem-content {
    max-width: 100%;
}

.topbar .toggleSearchMenu {
    opacity: 0;
    transition-duration: 0.33s;
}

.topbar .toggleSearchMenu.show {
    opacity: 1;
}

.searchMenu-type-hint {
    opacity: 0;
    transition-duration: 0.33s;
}

.searchMenu-search-container:focus-within + .searchMenu-type-hint {
    opacity: 1;
}

.searchMenu [data-search-suggestion] {
    padding: 0.5rem;
    border-radius: 10px;
    background: #eeeeee;
    text-decoration: none;
}

.mob-catalog-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
    gap: 0.5rem;
}

.catalog-grid-item {
    aspect-ratio: 1.5;
}

.catalog-grid-item-image {
    border-radius: var(--radius-large);
}

.catalog-grid-item-name {
    display: block;
    padding: 0.15rem;
    background: rgba(255,255,255,0.7);
    position: absolute;
    bottom: 0.25rem;
    left: 0.25rem;
    color: var(--color-text-h1);
    font-weight: 600;
    border-radius: var(--radius-small);
    font-size: var(--font-size-small);
    max-width: 95%;
}