/*#region tiny */

.mce-menubtn.mce-fixed-width span {
    width: auto !important;
}

.mce-active button[role=presentation] i {
    color: white;
}


/*#endregion */

.modal {
    z-index: 3001;
}

html {
    height: 100%;
    width: 100%;
}

body {
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: normal;
    background: #ffffff;
    position: relative;
    width: 100%;
    min-width: 320px;
    -webkit-font-smoothing: subpixel-antialiased !important;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
}

.clear {
    clear: both;
    visibility: hidden;
}

p {
    margin: 0 0 20px 0;
    line-height: 23px;
    font-weight: 400;
}

img {
    display: block;
    max-width: 100%;
    max-height: 100% !important;
}


/*== buttons
---------------------------------*/

button {
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
    overflow: visible;
    width: auto;
    outline: none;
}

button.button::-moz-focus-inner {
    padding: 0;
    border: 0;
}


/* FF Fix */

button.button {
    -webkit-border-fit: lines;
}


/* <- Safari & Google Chrome Fix */

.button {
    display: block;
    margin: 0px 0 10px 0;
    color: #010101;
    font-size: 19px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    text-decoration: none;
    background: none;
    cursor: pointer;
    box-sizing: border-box;
    transition: 0.5s;
    width: 87px;
    height: 33px;
    border: 2px solid black;
    border-radius: 5px;
}

.button:active,
.button:hover,
.button:focus {
    text-decoration: none;
    background: #363138;
    color: #ffffff;
    border-color: #363138;
}


/*== inputs
---------------------------------*/

input {
    outline: none;
}

.input-text,
textarea {
    display: block;
    margin: 0;
    padding: 5px 10px 5px 10px;
    height: 38px;
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    resize: none;
    outline: none;
    border: 1px solid #cacaca;
}

textarea {
    height: 80px;
}

textarea:focus {
    border: 1px solid #76c7c0;
    -webkit-box-shadow: 0 0 5px 2px rgba(118, 199, 192, 0.6);
    -moz-box-shadow: 0 0 5px 2px rgba(118, 199, 192, 0.6);
    box-shadow: 0 0 5px 2px rgba(118, 199, 192, 0.6);
}


/* End Total css ====*/


/* -------------- page index -------------- */
.b-product-img img {
    margin: 0 auto 0;
}

.tu-fa-lg2 {
    font-size: 1.5rem !important;
}

/* ---- b-footer ---- */

.footer-filler {
    flex-grow: 1;
}

.b-footer {
    /*position: absolute;*/
    width: 100%;
    background-color: rgba(242, 242, 242, 1);
    padding: 20px 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

body.stuck-bottom {
    min-height: 100%;
}

.b-footer.stuck-bottom {
    bottom: 0;
}

.b-footer-menu {
    margin: 0 10px 0 0;
}

.b-footer-menu b {
    display: block;
    color: #000;
    font-size: 18px;
    line-height: 20px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0 0 25px 0;
}

.b-footer-menu ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.b-footer-menu ul li a {
    display: block;
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-decoration: none;
    transition: 0.5s;
}

.b-footer-menu ul li a:hover {
    opacity: 0.7;
    transition: 0.5s;
}

.b-social ul {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
    margin: 0;
}

.b-social ul li {
    list-style: none;
    margin: 0 15px 0 0;
}

.b-social ul li:last-child {
    margin: 0;
}

.b-social ul li a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border: 1px solid #000;
    text-decoration: none;
}

.b-social ul li a i {
    color: #000;
    font-size: 16px;
    line-height: 18px;
    text-transform: uppercase;
}

.b-social ul li a:hover {
    background: #000;
    color: #fff;
    opacity: 1;
}

.b-social ul li a:hover i {
    color: #fff;
}

/* --------------- page category  --------------- */
.b-catalog-filters__left {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.b-aside-item {
    padding: 10px 5px;
    position: relative;
    margin: 0 0 20px 0;
}

.b-aside-modal {
    position: absolute;
    top: -2px;
    left: 0px;
    width: auto;
    background: #ffffff;
    padding: 5px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    z-index: 10002;
    display: none;
}

@media (min-width: 768px) {
    .b-aside-modal.large {
        position: fixed;
        top: 50%;
        left: 50%;
        width: 90vw;
        margin-left: -45vw;
        height: 90vh;
        margin-top: -45vh;
    }
}

.b-aside-search .btn-outline-secondary:hover {
    background: none;
    color: #fff;
}

.b-aside-item__title>a {
    color: #343a40;
    text-decoration: none;
}

.b-aside-item__title>a:hover {
    color: #007bff;
}

.b-aside-itemToggleFilter {
    font-size: 24px;
}

.has-aside-modal {
    position: relative;
}

.has-aside-modal.active .b-aside-modal {
    display: block;
    min-width: 320px;
}

@media (min-width: 400px) {
    .has-aside-modal.active .b-aside-modal {
        min-width: 400px;
    }
    .has-aside-modal.active .b-aside-modal .form-check-column {
        min-width: 350px;
    }
}

.b-aside-modal__content.columns-2 {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}

.b-aside-modal__content.columns-2>div {
    width: 50%;
    margin-bottom: 5px;
}

.b-aside-modal .b-aside-item__top {
    cursor: default;
}

.b-aside-modal .b-aside-item__top>.mdl-close {
    cursor: pointer;
    width: 20px;
}

.b-aside-modal .b-aside-item__top>.title {
    /*width: 100%;*/
    font-weight: bold;
}

.b-aside-modal .b-aside-item__top .form-check-inline {
    margin-right: 0;
}

.mdl-close {
    line-height: 17px;
    padding: 0 10px 0 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.b-aside-item__top {
    padding: 0 0 5px 0;
    position: relative;
    cursor: pointer;
    margin: 0 0 9px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.b-aside-options .b-aside-item__top {
    padding: 0;
    margin: 0;
    padding-bottom: 5px;
    margin-bottom: 10px;
}

.b-aside-item .b-aside-options.first .b-aside-item__top {
    border: none !important;
}

.btn-enter {
    border-radius: 5px;
    font-size: 14px;
    color: #ffffff;
    background-color: rgba(22, 155, 213, 1);
}

.b-aside-item__top b span {
    margin: 0 0 0 5px;
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
}

.b-aside-item__top b,
.tum-closeCats-wrap p.font-weight-bold {
    color: #000;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    text-transform: uppercase;
}

.b-checkbox__wrapper {
    margin: 0px 0 0 0;
}

.b-checkbox__wrapper__two {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}

.b-checkbox__wrapper__two>div {
    width: 100%;
}

.b-aside-item__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.btn-show {
    line-height: 18px;
    color: #000000;
    text-transform: capitalize;
    outline: none;
}

.btn-show i {
    padding: 0 0 0 5px;
    line-height: 22px;
}

.btn-ch {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    text-transform: capitalize;
    outline: none;
}

.btn-ch i {
    padding: 0 0 0 5px;
    font-size: 15px;
    line-height: 22px;
}

.b-aside-item__wrapper {
    display: none;
}

.b-aside-item ul {
    margin: 0 0 20px 0;
    padding: 0 20px 0 25px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.b-aside-item ul li {
    list-style: none;
}

.b-aside-item ul li:not(.list-group-item) a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #5f5f5f;
    font-size: 14px;
    font-weight: 400;
    line-height: 36px;
    text-decoration: none;
    transition: 0.3s;
}

.b-aside-item ul li a:hover {
    opacity: 0.5;
    transition: 0.3s;
}

.b-filter-price {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.b-filter-price__top {
    margin: 0 0 20px 0 !important;
}

.b-filter-price__top.mb-0 {
    margin: 0 0 0px 0 !important;
}

.b-filter-price__top span {
    display: block;
    color: #000;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    text-transform: uppercase;
}

.b-filter-price .ui-widget.ui-widget-content,
.b-filter-f-ch .ui-widget.ui-widget-content {
    border: none;
    background: #dcd9d9;
    height: 6px;
    margin: 0 0 15px 0;
}

.b-filter-price .ui-widget-header,
.b-filter-f-ch .ui-widget-header {
    background: grey;
}

.b-filter-price .ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
.ui-button,
html .ui-button.ui-state-disabled:hover,
html .ui-button.ui-state-disabled:active {
    width: 12px;
    height: 12px;
    background: grey;
    border-radius: 100%;
    border: none;
    outline: none;
    top: -3px !important;
}

.b-filter-price__wrapper,
.b-filter-f-ch__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.b-filter-price__wrapper span,
.b-filter-f-ch__wrapper span {
    color: #a0a0a0;
    font-size: 13px;
    font-weight: 400;
    line-height: 15px;
}

.b-filter-price__input,
.b-filter-f-ch_input {
    width: 100%;
    height: 35px;
    background: #ffffff;
    color: #252525;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-align: center;
}

.b-catalog-filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.b-catalog-filters__left span {
    opacity: 0.8;
    color: #252525;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 3px;
}

.b-catalog-wrapper .b-product, .prod-cont .b-product {
    margin: 0px 0px 20px 0px;
}

.btn-products {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto 0;
}

.btn-products b {
    color: #383385;
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    padding: 0 0 2px 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-bottom: 1px dashed #383385;
}


/* --------------- page card  --------------- */

#map {
    width: 100%;
    height: 500px;
    margin: 30px 0 0 0;
}

#contractor-pricelist-map {
    width: 100%;
    height: 300px;
}

.b-product-content__item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 10px 0;
}

.b-product-content__item i {
    font-size: 15px;
    line-height: 17px;
}

.b-product-content__item a:not(:last-child) i {
    margin: 0 13px 0 0;
}

.b-product-content__item span {
    font-size: 15px;
    line-height: 17px;
}

.b-product-content__item .fluid {
    width: 100%;
}

.b-product-content__item .dropdown-toggle:after,
.b-product-content__item .dropdown-toggle:before {
    content: '';
    display: none;
}

.b-product-content p:last-child {
    margin-bottom: 0;
}

.b-content-title {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin: 15px 0;
}

.b-catalog-slider__top {
    padding: 5px 0;
    margin: 15px 0 0;
}

.b-catalog-slider__top hr {
    margin: 8px 0;
}

.b-aside-item__top__btns {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.b-bottom__nav ul {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}

.b-bottom__nav ul li {
    list-style: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 10px;
}

.b-bottom__nav ul li i {
    font-size: 16px;
    line-height: 18px;
    color: #000000;
    margin: 0 9px 0 0;
}

.b-bottom__nav ul li a {
    font-size: 16px;
    line-height: 18px;
    color: #000000;
    font-weight: 400;
}

.b-content-title__two {
    margin: 0;
    padding: 0;
}

.b-aside-modal .collapse-header .selected-items {
    padding-left: 15px;
    font-size: 11px;
}

.b-aside-modal .selected-items .value {
    display: inline-block;
}

.b-contactor-catalog__tab .load-more-products {
    font-size: 18px;
    margin-top: 20px;
    display: inline-block;
}

.b-cropped-text .more {
    display: none;
}

.dropdown-toggle.w-icon:after,
.dropdown-toggle.w-icon:before {
    display: none;
}

.popover {
    z-index: 10002;
}

.b-aside-xs:hover .b-aside-item__bot .btn-ch {
    visibility: visible !important;
}

.tum-flt-aside-modal {
    max-height: 100%;
    overflow-y: auto;
    min-height: 220px;
}


/*	symbols ... for breadcumbs */

.dots:not(:empty) {
    background-color: #f8f9fa;
    border-radius: 10px;
    cursor: pointer;
    color: #007bff;
}

.dots .fas {
    margin-left: 8px;
}

.tum-head-ellipses {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 20px;
}

.tum-shadow {
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, .24);
}

.tum-border-bottom {
    border-bottom: 10px solid #f2f2f2;
}

.tum-border-bottom-2 {
    margin-right: -8px;
    margin-left: -8px;
}

.tum-pb-0 {
    padding-bottom: 0px!important;
}

.b-filter-price__input,
.b-filter-f-ch__input {
    max-width: 110px;
}

.dropdown-menu {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.25) !important;
}

.dropdown-menu.tum-box-attention {
    box-shadow: 0 1px 10px 5px rgba(255, 0, 0, 0.45) !important;
}

.form-control.error {
    border-color: #ca1f2f;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(202, 31, 47, .6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(202, 31, 47, .6);
}

.tum-profileMenuButtonCont {
    display: flex;
    justify-content: center;
}

.text-bold {
    font-weight: bold;
}

.show-search-menu-focus {
    cursor: pointer;
}

.important-border {
    border: 2px solid var(--color-primary) !important;
}
