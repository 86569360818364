
.tum-to-bottom {
	display: block;
	position: fixed;
	bottom: 23px;
	right: 30px;
	z-index: 99;
	border: none;
	outline: none;
	color: #d91628 /*#ca1f2f*/;
	cursor: pointer;
	font-size: 44px;
	z-index: 1 !important;
}

.tum-to-bottom:hover {
	color: #830A16;
	text-decoration: none;
}

.tum-action-container {
	position: fixed;
	bottom: 28px;
	right: 19px;
	width: 400px;
	z-index: 4;
}

@media (min-width: 768px){
	.action-items {
		padding: 1.5rem 0 1.5rem 10px !important;
	}
	.tum-block-operator {
		margin-right: 1rem !important;
	}
	.tum-block-operator .operatorChat{
		justify-content: unset !important;
	}
}
@media (max-width: 767px){
	.tum-action-container {
		right: 24px !important;
		width: 290px !important;
	}
	.action-items {
		padding: 1.5rem 0 !important;
	}
	.tum-block-operator .tum-icon-chatOperator {
		width: 32px;
	}
}

.modal-actions {
	border-radius: 5px;
	box-shadow: 1px 2px 23px 10px #000 !important;
	background: white;
	z-index: 2;
	position: relative;
	padding: 10px;
}

.tum-block-operator a:hover, .tum-block-profile a:hover{
	text-decoration: none;
}