[data-switch-frames] {
    position: relative;
}

[data-switch-frames] [data-frame] {
    opacity: 0;
    z-index: -1;
    transition: 0.2s;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

[data-switch-frames] [data-frame].active {
    opacity: 1;
    z-index: 1;
}