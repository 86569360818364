.banners-slider {
    margin: 1rem 0;
}

.banners-slider .slider-list {
    align-items: center;
    margin: 0 auto;
    width: fit-content;
    max-width: 100%;
}

.banner-slide {
    width: 330px;
    height: 140px;
    min-width: 330px;
    min-height: 140px;
    position: relative;

    box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 6%);
    margin: 0.25rem 0.75rem 0.25rem 0.25rem;
    line-height: 1em;
    border-radius: 5px;
}

.banner-slide-img {
    margin: 0 auto;
    border-radius: 5px;
    box-shadow: 1px 3px 3px 0 rgba(0,0,0,.32);
    width: auto;
    height: auto;
}

.banner-slide .banner-expand {
    position: absolute;
    top: 3px;
    right: 3px;

    align-items: center;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    transition: .2s;

    width: 20px;
    height: 20px;
    background-color: hsl(0deg 0% 100% / 52%);
    margin: 3px 1px;
}

.banner-slide .banner-expand:hover {
	background-color: #bdbdbd;
	text-decoration: none;
}

