body.modal-open {
    overflow: hidden !important;
}

.tum-card-linkUnder span {
    cursor: pointer;
}

textarea {
    resize: vertical !important;
    overflow: auto !important;
}

@media(max-width: 768px) {
    .relative {
        position: relative !important;
    }

    .resize-textarea-up {
        position: absolute;
        display: block !important;
        right: 0px;
        top: 0;
        font-size: 20px;
        padding: 0px 0px 0px 10px;
    }

    .resize-textarea-down {
        position: absolute;
        display: block !important;
        right: 0px;
        bottom: 0px;
        font-size: 20px;
        padding: 0px 0px 0px 10px;
    }

    fieldset.relative .resize-textarea-up {
        top: 7px;
    }
}

.resize-textarea-down,
.resize-textarea-up {
    display: none;
}

.container-fluid.full-height {
    min-height: 100vh;
}

body {
    font-family: 'Open Sans', sans-serif;
    font-stretch: condensed;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.btn-default.align-self-center {
    border: 1px solid silver;
}


/*---------------basic element-----------------*/

.form-control {
    border-radius: 0px !important;
}

.dropdown-menu {
    border-radius: 0px;
}

.modal-open {
    overflow-y: auto;
}

.btn.focus,
.btn:focus {
    outline: 0;
    box-shadow: none;
}

/*--------------- select2 -----------------*/

.select2-container .select2-selection--single,
.select2-dropdown .select2-dropdown--below {
    height: auto !important;
    border-radius: 0px !important;
    border-color: #ced4da;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 1.5;
    font-size: 1rem;
    padding: .375rem .75rem;
    color: #495057 !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 38px;
}


/*--------------- Schedule table -----------------*/

.tum-shedule-table thead th {
    border: none;
    background-color: #f2efe5;
}

.tum-shedule-table td {
    border: 1px solid #f2efe5;
    padding: .25rem 0rem;
}

.tum-product-test-warning {
    border: 3px solid #ca1f2f;
    background-color: #ffe9ff;
}

.tum-product-test-info {
    border-top: 1px solid #ca1f2f;
    color: #ff9900;
}

.tum-product-test-warning p,
.tum-product-test-info p {
    line-height: 1rem;
    text-align: center;
    font-size: 14px;
}

.tum-banner-header {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tum-banner-header:hover {
    white-space: normal;
}

@media(max-width: 768px) {
    .ui-tooltip {
        max-width: 200px;
    }
}

.ui-tooltip {
    word-break: break-all;
}

.tum-seofooter {
    border: 1px solid rgba(0, 0, 0, .025);
}

.tum-input-label {
    min-width: 0;
    padding: 0 1px 0 1px;
    margin: 0;
    border: 0;
    border: 2px solid #1A73E8;
    border-radius: 3px;
    width: 100%;
}

.tum-input-block {
    padding: 0 5px;
    font-size: 12px;
    display: block;
    color: #4B91ED;
}

legend {
    width: auto;
    margin-left: 10px;
    margin-bottom: 0;
    margin-right: 4px;
    word-break: break-word;
}

fieldset .form-control:focus {
    box-shadow: none;
    border-color: transparent;
}

fieldset .form-control {
    display: block;
    width: 100%;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 0px solid #1A73E8;
    border-top: 0;
    border-bottom: 0;
    margin: -4px 0px 0px 0px;
    padding-left: -87px;
    border-radius: .25rem;
}

fieldset textarea.form-control {
    border: 0 solid #1A73E8;
    margin: 0px 0px 1px 0px;
    padding-top: 0;
    line-height: 1.2;
}

@media(max-width: 768px) {
    fieldset textarea.form-control:not(.full-height) {
        min-height: 90px !important
    }

    #filterEditModal textarea {
        min-height: 90px !important
    }
}

fieldset .form-control:focus .tum-input-label {
    border-color: red;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
}

.fs-16 {
    font-size: 16px !important;
}

.fs-14rem {
    font-size: 1.4rem !important;
}

.tum-mob-menu-toggler {
    border: none !important;
}

.tum-mobCloseButtonAnimation {
    transform: rotate(360deg)
}

.show>.btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: none;
}

.tum-errorObject {
    position: fixed;
    top: 30px;
    right: 20px;
    font-size: 50px;
    z-index: 5000;
    cursor: pointer;
    background: #fff;
    border: 5px solid #fff;
    border-radius: 30px;
    padding: 10px;
    background-color: #eac600;
}

.tum-errorObject .tum-error-selected {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: 150px;
    font-weight: bold;
    font-size: large;
    margin-left: 7px;
}

.tum-errorObject .error-selected-text {
    border-bottom: 2px solid rgb(255 255 255 / 79%);
    box-shadow: 0px 6px 6px -4px rgb(172 172 172 / 62%);
}

.tum-errorObject .msg+.tum-error-icons {
    display: flex;
}

.tum-errorObject .msg:not(.d-none)+.tum-error-icons {
    margin-top: .7rem;
}

.tum-errorObject .msg.d-none+.tum-error-icons {
    align-items: flex-start;
}

.tum-errorObject .msg:not(.d-none)+.tum-error-icons .hideTumErrorObject {
    display: none;
}

.tum-errorObject .msg.d-none+.tum-error-icons .hideTumErrorObject {
    font-size: 14px;
    align-items: flex-end;
}

.tum-errorObject .tum-error-icons .hideTumErrorObject:hover {
    text-decoration: none;
}

@media(max-width: 400px) {
    .tum-errorObject .tum-error-selected {
        min-width: 140px !important;
        max-width: 140px;
    }

    .tum-errorObject {
        right: 0px !important;
    }

    .tum-errorObject .msg {
        max-width: 250px !important;
        margin-right: 0px !important;
    }
}

.tum-errorObject .msg {
    display: inline-block;
    max-width: 250px;
    margin-right: 30px;
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    line-height: 23px;
}

.tum-errorObject .hideTumErrorObject {
    vertical-align: text-top;
}

.dropdown-menu a.menu-action-header {
    white-space: normal;
}

.tum-changeCity {
    position: relative;
    text-decoration: underline dotted;
    text-decoration-skip-ink: none;
}

/*Блок предупреждения о отключенном JS */

.tum-agreement-js-window {
    background: #f1f1f1;
    position: fixed;
    bottom: 30px;
    left: 50%;
    width: 700px;
    padding: 15px;
    box-sizing: border-box;
    color: #000;
    font-size: 14px;
    z-index: 9990;
    text-align: center;
    opacity: 1;
    margin-left: -350px;
    visibility: visible;
    box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.55);
    -webkit-box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.55);
    -o-box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.55);
    -webkit-transition: opacity .5s, visibility;
    -moz-transition: opacity .5s, visibility;
    -o-transition: opacity .5s, visibility;
    transition: opacity .5s, visibility;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.33);
}

.tum-agreement-js-window i {
    font-size: 400%;
}

.tum-agreement-js-window p {
    margin-bottom: 3px;
}

@media(max-width: 768px) {
    .tum-agreement-js-window {
        left: 5%;
        width: 90%;
        margin-left: 0px;
    }
}

.tum-sendErrorIcon {
    text-decoration: none;
}

.tum-sendErrorIcon:hover {
    color: #e3c113;
    text-decoration: none;
}

.tum-emptyCardFiller {
    height: 365px;
}

.b-catalog-slider .product-card.tum-oneCatProductAddBtn {
    margin-top: 20px;
}


/* progressbar start */

.loader,
.loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
}

.loader-small {
    font-size: 2.5px !important;
}

.loader {
    margin: 32px auto;
    font-size: 4.5px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.75em solid rgba(205, 28, 43, 0.2);
    border-right: 1.75em solid rgba(205, 28, 43, 0.2);
    border-bottom: 1.75em solid rgba(205, 28, 43, 0.2);
    border-left: 1.75em solid #cd1c2b;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}


/* progressbar end */

.locationDetectPopoverCont {
    background: #f1f1f1;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, .55);
    font-size: 16px;
}

.locationDetectPopoverCont .arrow:after {
    border-bottom-color: #f1f1f1;
}

.tum-location-selectCityAndPool {
    padding-top: 0px;
    padding-bottom: 0px;
}

#as-u5-crop {
    max-height: 70vh;
}

.grecaptcha-badge {
    display: none;
}

.political-google-captcha {
    font-size: 10px;
}

.as-notification-cont.show {
    position: absolute;
    width: 310px;
    font-size: .85rem;
    z-index: 5000;
    left: -270px;
}

@media (max-width: 360px) {
    .as-notification-cont.show {
        left: -230px;
    }
}

.as-notification-cont .btn-xs {
    padding: 3px 20px;
    font-size: 12px;
    border-radius: 3px !important;
}

.as-notification-cont .btn-default {
    color: #3a3b45;
    background-color: #f8f9fc;
    border-color: #f8f9fc;
}

.as-notification-item-cont {
    height: unset !important;
}

.as-notification-cont p {
    margin: 0;
    padding: 0;
    line-height: unset;
    font-size: 100%;
}

.tum-readmore-0:not(.show),
.tum-readmore-1:not(.show),
.tum-readmore-2:not(.show),
.tum-readmore-custom:not(.show) {
    overflow: hidden;
}

.tum-readmore-inline {
    display: none;
}

.tum-readmore-0 {
    max-height: 0 !important;
}

.tum-readmore-content {
    overflow: hidden;
}

.tum-readmore-1 {
    max-height: 1.3em !important;
}

.tum-readmore-2 {
    max-height: 2.6em !important;
}

.tum-readmore-custom {
    max-height: var(--readmore-height, 0) !important;
}

.tum-readmore-0.show,
.tum-readmore-1.show,
.tum-readmore-2.show,
.tum-readmore-custom.show {
    max-height: 1000000px !important;
    /* вместо initial, чтобы в хроме не срабатывало кривое увеличение шрифтов на телефонах */
}

.tum-readmore-inline.show {
    display: inline;
}

.badge-redOnWhite {
    color: #ca1f2f;
    background-color: #fff;
}

.tum-alerts-content {
    max-height: 65vh;
    overflow: auto;
}

.modal-danger .modal-body {
    background-color: #fff5f5;
}

.modal-success .modal-header,
.modal-success .modal-footer {
    background-color: #f1f1f1;
}

@media (min-width: 993px) {
    .modal-full {
        min-width: 97vw;
    }

    .modal-full .modal-content {
        height: 95vh;
        overflow: auto;
    }

    .modal-full .modal-body {
        max-height: calc(95vh - 122px);
        overflow: auto;
    }

    .tum-alerts-content {
        max-height: initial;
    }

    .modal-full .modal-footer {
        padding: 0.5rem;
    }
}

.modal-title {
    line-height: 1.1;
}

.text-height-1-1 {
    line-height: 1.1;
}

.tum-border-left-grey {
    border-left: 2px solid gainsboro;
}

.tum-block-grey {
    padding: 0.3rem;
    border: 1px solid gainsboro;
    border-radius: 5px;
    background-color: #f2f2f2;
}

.tum-block-light {
    padding: 0.3rem;
    border: 1px solid #e2e2e2;
    border-radius: 5px;
    background-color: #ffffe9;
}

.tum-underline-dashed-dark-red {
    text-decoration: underline dashed #ca1f2f;
}

.tum-error-block {
    color: #ca1f2f;
}

.max-width-90 {
    max-width: 90%;
}

.tum-link-blue {
    color: #007bff !important;
}

.tum-bg-yellow {
    background-color: #e3c113 !important;
}

.tum-ribbon {
    text-transform: uppercase !important;
    font-weight: 400 !important;
    border-top: none !important;
    padding-left: 15px !important;
    color: #212529 !important;
    height: 37px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.b-catalog-slider .tum-ribbon {
    margin: 0;
}
