/* slider */
.tu-slider {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.tu-slider .slider-controls {
	flex-grow: 1;
}

.slider-slide {
    flex: 0 0 267px;
	display: flex;
	flex-direction: column;
}

.slider-list {
	flex: 0 0 100%;
	display: flex;
	align-items: stretch;
	overflow-x: scroll;
	flex-wrap: nowrap;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	column-gap: 1rem;
	row-gap: 1.5rem;
}

@media (max-width: 767px) {
	.tu-slider.mob-grid .slider-list {
		display: grid;
		column-gap: 0.25rem;
		row-gap: 0.75rem;
		grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
		overflow-x: hidden;
	}
}

@media (min-width: 768px) {
	.slider-list {
		-ms-overflow-style: none;
		scrollbar-width: none;
	}

		.slider-list::-webkit-scrollbar {
			display: none;
		}
}

.slider-loadMoreCont {
	margin-top: 0.5rem;
	display: flex;
	min-width: 100vw;
	justify-content: center;
	align-items: center;
	height: 48px;
	grid-column-start: 1;
}

.tu-slider .slider-loadMore {
	z-index: 3;
	font-size: 3rem;
    color: #00000042;
}

.slider-left-btn, .slider-right-btn {
	position: absolute;
	top: 50%;
	font-size: 3rem;
	color: #00000042 !important;
	z-index: 3;
}

.slider-left-btn {
	left: -25px;
}

.slider-controls a {
	display: inline-flex;
	color: var(--color-text-h1);
	align-items: center;
  	background-color: var(--color-bg-secondary);;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	justify-content: center;
}

.slider-controls a.disabled {
	color: #c9c9c9;
}

.slider-right-btn {
	right: -25px;
}

	.slider-left-btn.disabled, .slider-right-btn.disabled {
		display: none;
	}

	.slider-left-btn:hover, .slider-right-btn:hover {
		text-decoration: none;
	}